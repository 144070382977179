import React, { FunctionComponent, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Match } from 'react-router-dom';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { store } from 'store/store';
import { SET_DRAFT_PLAYER, SET_GAME } from 'store/actions';
import { createPlayer } from 'services/player.service';

import './NewPlayerRow.scss';

type NewPlayerRowProps = {
  match: Match,
}

const NewPlayerRow: FunctionComponent<NewPlayerRowProps> = ({ match }) => {
  const { state, dispatch } = useContext(store);

  function setDraftPlayer(name) {
    dispatch({ type: SET_DRAFT_PLAYER, draftPlayer: name });
  }

  async function savePlayer() {
    const player = await createPlayer({
      name: state.draftPlayer,
      gameId: match.params.gameId,
    });

    const newGame = {
      ...state.game,
      players: [player].concat(state?.game?.players || []),
    };
    dispatch({ type: SET_GAME, game: newGame });
    dispatch({ type: SET_DRAFT_PLAYER, draftPlayer: null });
  }

  return (
    <div className="NewPlayerRow">
      <input
        className="player-name"
        placeholder="Enter a name"
        value={state.draftPlayer || ''}
        onChange={event => setDraftPlayer(event.target.value)}
      />
      <div className="player-details" onClick={() => savePlayer()}>
        <FontAwesomeIcon className="check-icon" icon={faCheck} />
      </div>
    </div>
  );
}

export default withRouter(NewPlayerRow);
