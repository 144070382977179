import React, { FunctionComponent, ReactNode, useContext } from "react";
import { store } from "store/store";
import { Buyin } from "types/types";

import "./PlayerPage.scss";
import PlayerNavbar from "./PlayerNavbar/PlayerNavbar";
import BuyinRow from "./BuyinRow/BuyinRow";
import Footer from "components/Footer/Footer";

const PlayerPage: FunctionComponent = () => {
  const { state } = useContext(store);

  function getTotalBuyin() {
    const buyins = state?.player?.buyins || [];
    return buyins
      .reduce((a, b) => a + b.amount, 0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="PlayerPage">
      <PlayerNavbar />
      <div className="player-summary">
        <div className="player-name">
          {state?.player?.player_name || "Player not found"}
        </div>
        <div className="player-stats">
          <div className="stats-group">
            <div className="group-amount">
              {state?.player?.buyins.length || 0}
            </div>
            <div className="group-label">buyins</div>
          </div>
          <div className="stats-group">
            <div className="group-amount">
              <sup>$</sup>
              {getTotalBuyin()}
            </div>
            <div className="group-label">on the table</div>
          </div>
        </div>
      </div>
      <div className="buyins-container">
        {state?.player?.buyins.map((buyin) => render(buyin))}
      </div>
      <Footer />
    </div>
  );
};

const render = (buyin: Buyin): ReactNode => {
  return <BuyinRow key={buyin.id} buyin={buyin} />;
};

export default PlayerPage;
