import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import './App.scss';
import HomePage from 'pages/HomePage/HomePage';
import GamePage from 'pages/GamePage/GamePage';
import PlayerPage from 'pages/PlayerPage/PlayerPage';
import CashoutPage from 'pages/CashoutPage/CashoutPage';
import ResultsPage from 'pages/ResultsPage/ResultsPage';
import PaymentsPage from 'pages/PaymentsPage/PaymentsPage';

export default () => {
  return (
    <Fragment>
      <div className="App mobile">
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>

            <Route exact path="/games/:gameId">
              <GamePage />
            </Route>

            <Route exact path="/games/:gameId/cashout">
              <CashoutPage />
            </Route>

            <Route exact path="/games/:gameId/results">
              <ResultsPage />
            </Route>

            <Route exact path="/games/:gameId/payments">
              <PaymentsPage />
            </Route>

            <Route exact path="/games/:gameId/players/:playerId">
              <PlayerPage />
            </Route>
          </Switch>
        </Router>
      </div>

      <div className="App desktop">
        PokerHost is currently available only on mobile devices.
      </div>
    </Fragment>
  );
}
