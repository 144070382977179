import React, { FunctionComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Payment } from 'types/types';

import './PaymentRow.scss';

type PaymentRowProps = {
  payment: Payment,
}

const PaymentRow: FunctionComponent<PaymentRowProps> = ({ payment }) => {
  function getPaymentAmount() {
    return parseFloat(payment.amount).toFixed(2).replace(/[.,]00$/, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="PaymentRow">
      <div className="payment-direction">
        <span className="payer-name">
          { payment.payer.player_name }
        </span>
        pays
        <span className="payee-name">
          { payment.payee.player_name }
        </span>
      </div>
      <div className="payment-details">
        <div className="player-balance">
          <div className="balance-amount">${getPaymentAmount()}</div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PaymentRow);
