import React, { FunctionComponent, useContext } from 'react';
import { withRouter, History } from 'react-router-dom';
import { store } from 'store/store';
import { SET_GAME } from 'store/actions';
import { Game } from 'types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import './GameRow.scss';

type GameRowProps = {
  game: Game,
  history: History,
}

const GameRow: FunctionComponent<GameRowProps> = ({ game, history }) => {
  const state = useContext(store);
  const { dispatch } = state;

  function viewGame(game) {
    dispatch({ type: SET_GAME, game });

    if (game.ended_at) {
      history.push(`/games/${game.id}/results`);
    } else {
      history.push(`/games/${game.id}`);
    }
  }

  function nameColorClass() {
    if (game.ended_at) {
      return 'blue';
    } else {
      return '';
    }
  }

  const gameName = game.id.substring(game.id.length - 4).toUpperCase();
  const gameDate = (new Date(game.created_at)).toLocaleDateString('en-US');

  return (
    <div className="GameRow" onClick={() => viewGame(game)}>
      <div className={`game-name ${nameColorClass()}`}>
        { gameName } - { gameDate }
      </div>
      <div className="game-details">
        <div className="game-players">
          <div className="players-label">players</div>
          <div className="players-amount">{game.players.length}</div>
        </div>

        <FontAwesomeIcon className="angle-icon" icon={faAngleRight} />
      </div>
    </div>
  );
}

export default withRouter(GameRow);
