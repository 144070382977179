import React, { FunctionComponent, useContext } from 'react'
import { withRouter, History } from 'react-router-dom';
import { store } from 'store/store';
import { SET_GAME, SET_DRAFT_PLAYER } from 'store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import './GameNavbar.scss';

type GameNavbarProps = {
  history: History,
}

const GameNavbar: FunctionComponent<GameNavbarProps> = ({ history }) => {
  const { dispatch } = useContext(store);

  function back() {
    dispatch({ type: SET_GAME, game: null });
    history.push(`/`);
  }

  function addPlayer() {
    dispatch({ type: SET_DRAFT_PLAYER, draftPlayer: '' });
  }

  return (
    <div className="GameNavbar">
      <div className="nav-control left" onClick={() => back()}>
        <FontAwesomeIcon className="angle-icon" icon={faAngleLeft} />
        <span className="back-label">Back</span>
      </div>
      <div className="page-title">
        Game Details
      </div>
      <div className="nav-control right" onClick={() => addPlayer()}>
        <span className="add-label">Add Player</span>
      </div>
    </div>
  );
}


export default withRouter(GameNavbar);
