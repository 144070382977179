import axios from 'axios';

export const createPlayer = async ({ name, gameId }) => {
  const params = {
    player_name: name,
    game_id: gameId,
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/players`, params);
  return response.data;
}
