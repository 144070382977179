import axios from "axios";

export const createBuyin = async ({ playerId, gameId, amount }) => {
  const params = {
    player_id: playerId,
    game_id: gameId,
    amount,
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/buyins`,
    params
  );
  return response.data;
};

export const deleteBuyin = async ({ id }) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/buyins/${id}`
  );
  return response.data;
};
