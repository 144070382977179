import React, { FunctionComponent, useContext } from 'react';
import { withRouter, History, Match } from 'react-router-dom';
import { store } from 'store/store';
import { SET_PLAYER } from 'store/actions';
import { Player } from 'types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import './PlayerRow.scss';

type PlayerRowProps = {
  player: Player,
  history: History,
  match: Match,
}

const PlayerRow: FunctionComponent<PlayerRowProps> = ({ player, history, match }) => {
  const state = useContext(store);
  const { dispatch } = state;

  function viewPlayer(player) {
    dispatch({ type: SET_PLAYER, player });
    history.push(`/games/${match.params.gameId}/players/${player.id}`);
  }

  function getTotalBuyin() {
    return player.buyins.reduce((a, b) => a + b.amount, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="PlayerRow" onClick={() => viewPlayer(player)}>
      <div className="player-name">
        { player.player_name }
      </div>
      <div className="player-details">
        <div className="player-balance">
          <div className="balance-label">bought in for</div>
          <div className="balance-amount">${getTotalBuyin()}</div>
        </div>

        <FontAwesomeIcon className="angle-icon" icon={faAngleRight} />
      </div>
    </div>
  );
}

export default withRouter(PlayerRow);
