import React, { FunctionComponent, useContext } from 'react';
import { withRouter, History, Match } from 'react-router-dom';
import { store } from 'store/store';
import { SET_CASHOUTS } from 'store/actions';
import { createCashout } from 'services/cashout.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import './CashoutNavbar.scss';

type CashoutNavbarProps = {
  history: History,
  match: Match,
}

const CashoutNavbar: FunctionComponent<CashoutNavbarProps> = ({ history, match }) => {
  const { state, dispatch } = useContext(store);

  function back() {
    dispatch({ type: SET_CASHOUTS, cashouts: {} });
    history.push(`/games/${match.params.gameId}`);
  }

  function cashout() {
    const gameId = match.params.gameId;
    createCashout({ gameId, cashouts: state.cashouts }).then(game => {
      dispatch({ type: SET_CASHOUTS, cashouts: {}, game });
      history.push(`/games/${gameId}/results`);
    });
  }

  function disabled() {
    const cashoutTotal = Object.values(state.cashouts).reduce((a, b) => a + parseFloat(b), 0);
    const buyins = state.game?.players.map(player => player.buyins).flat() || [];
    const buyinTotal = buyins.reduce((a, b) => a + b.amount, 0);

    return buyinTotal - cashoutTotal !== 0;
  }

  return (
    <div className="CashoutNavbar">
      <div className="nav-control left" onClick={() => back()}>
        <FontAwesomeIcon className="angle-icon" icon={faAngleLeft} />
        <span className="back-label">Back</span>
      </div>
      <div className="page-title">
        Cashout Details
      </div>
      <div className={`nav-control right ${disabled() ? 'disabled' : ''}`} onClick={() => cashout()}>
        <span className="add-label">Cashout</span>
      </div>
    </div>
  );
}


export default withRouter(CashoutNavbar);
