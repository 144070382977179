import React, { FunctionComponent, useContext } from 'react';
import { withRouter, History } from 'react-router-dom';
import { store } from 'store/store';
import { createGame } from 'services/game.service';
import { retrieveGames, storeGames } from 'services/storage.service';
import { SET_GAME } from 'store/actions';
import './HomeNavbar.scss';

type HomeNavbarProps = {
  history: History,
}

const HomeNavbar: FunctionComponent<HomeNavbarProps> = ({ history }) => {
  const state = useContext(store);
  const { dispatch } = state;

  async function createGameAndRedirect() {
    const game = await createGame();

    storeGames(retrieveGames().concat([game]));
    dispatch({ type: SET_GAME, game });

    history.push(`/games/${game.id}`);
  }

  return (
    <div className="HomeNavbar">
      <div className="nav-control left">
        <span className="back-label"></span>
      </div>
      <div className="page-title">
        Dashboard
      </div>
      <div className="nav-control right" onClick={() => createGameAndRedirect()}>
        <span className="add-label">New Game</span>
      </div>
    </div>
  );
}


export default withRouter(HomeNavbar);
