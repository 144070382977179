import React, { FunctionComponent, useContext } from "react";
import { withRouter, History, Match } from "react-router-dom";
import { store } from "store/store";
import { Player } from "types/types";
import { SET_PLAYER } from "store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { createBuyin } from "services/buyin.service";
import "./PlayerNavbar.scss";

type PlayerNavbarProps = {
  history: History;
  match: Match;
};

const PlayerNavbar: FunctionComponent<PlayerNavbarProps> = ({
  history,
  match,
}) => {
  const { dispatch, state } = useContext(store);

  function back() {
    dispatch({ type: SET_PLAYER, player: null });
    history.push(`/games/${match.params.gameId}`);
  }

  async function topUp() {
    const buyin = await createBuyin({
      playerId: match.params.playerId,
      gameId: match.params.gameId,
      amount: 100,
    });

    const newPlayer: Player = {
      id: state.player!.id,
      player_name: state.player!.player_name,
      created_at: state.player!.created_at,
      buyins: [buyin].concat(state?.player?.buyins || []),
      cashouts: state.player!.cashouts,
    };

    const newPlayers = state?.game?.players.map((player) => {
      if (player.id === newPlayer.id) {
        return newPlayer;
      } else {
        return player;
      }
    });

    const newGame = {
      ...state.game,
      players: newPlayers,
    };

    dispatch({ type: SET_PLAYER, player: newPlayer, game: newGame });
  }

  return (
    <div className="PlayerNavbar">
      <div className="nav-control left" onClick={() => back()}>
        <FontAwesomeIcon className="angle-icon" icon={faAngleLeft} />
        <span className="back-label">Back</span>
      </div>
      <div className="page-title">Player Details</div>
      <div className="nav-control right" onClick={() => topUp()}>
        Add $100
      </div>
    </div>
  );
};

export default withRouter(PlayerNavbar);
