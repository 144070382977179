import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { store } from 'store/store';
import { Player } from 'types/types';

import './ResultsPage.scss';
import ResultsRow from './ResultsRow/ResultsRow';
import ResultsNavbar from './ResultsNavbar/ResultsNavbar';
import Footer from 'components/Footer/Footer';

const ResultsPage: FunctionComponent = () => {
  const { state } = useContext(store);

  function getPlayerCount() {
    return state?.game?.players?.length || 0
  }

  function getTotalBuyin() {
    const buyins = state?.game?.players.map(player => player.buyins).flat() || [];
    return buyins.reduce((a, b) => a + b.amount, 0).toFixed(2).replace(/[.,]00$/, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const gameName = state?.game?.id.substring(state?.game?.id.length - 4).toUpperCase();
  const rawDate = state?.game?.created_at;
  const gameDate = rawDate ? (new Date(rawDate)).toLocaleDateString('en-US') : null;

  return (
    <div className="ResultsPage">
      <ResultsNavbar />
      <div className="game-summary">
        <div className="game-title">{ gameName || 'Game not found'} - { gameDate }</div>
        <div className="game-stats">
          <div className="stats-group">
            <div className="group-amount">{getPlayerCount()}</div>
            <div className="group-label">players</div>
          </div>
          <div className="stats-group">
            <div className="group-amount"><sup>$</sup>{getTotalBuyin()}</div>
            <div className="group-label">on the table</div>
          </div>
        </div>
      </div>
      <div className="results-container">
        { state?.game?.players.map(player => renderPlayer(player)) }
      </div>
      <Footer />
    </div>
  );
}

const renderPlayer = (player: Player): ReactNode => {
  return (
    <ResultsRow key={player.id} player={player} />
  );
}


export default withRouter(ResultsPage);
