import React, {createContext, useReducer, Dispatch } from 'react';
import { SET_PLAYER, SET_GAME, SET_DRAFT_PLAYER, SET_CASHOUTS } from './actions';
import { Game, Player } from 'types/types';

type State = {
  game: Game | null,
  player: Player | null,
  draftPlayer: string | null;
  cashouts: object;
}
type Context = {
  state: State,
  dispatch: Dispatch<any>,
}

const initialState: State = {
  game: null,
  player: null,
  draftPlayer: null,
  cashouts: {},
};
const initialContext: Context = {
  state: initialState,
  dispatch: () => {},
}

const store = createContext(initialContext);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case SET_GAME:
        return {
          ...state,
          game: action.game,
          player: null,
        };
      case SET_PLAYER:
        return {
          ...state,
          game: action.game || state.game,
          player: action.player,
        };
      case SET_DRAFT_PLAYER:
        return {
          ...state,
          draftPlayer: action.draftPlayer,
        };
      case SET_CASHOUTS:
        return {
          ...state,
          cashouts: action.cashouts,
          game: action.game || state.game,
        };
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
