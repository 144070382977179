import axios from 'axios';

export const createCashout = async ({ gameId, cashouts }) => {
  const params = {
    game_id: gameId,
    cashouts: Object.keys(cashouts).map(playerId => {
      return {
        player_id: playerId,
        amount: cashouts[playerId],
      };
    }),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/cashouts`, params);
  return response.data;
}
