import axios from 'axios';

export const createGame = async () => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/games`);
  return response.data;
}

export const getGames = async ids => {
  const params = { ids };
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/games`, { params });
  return response.data;
}
