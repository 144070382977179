import React, { FunctionComponent } from 'react'
import { withRouter, History, Match } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import './PaymentsNavbar.scss';

type PaymentsNavbarProps = {
  history: History,
  match: Match,
}

const PaymentsNavbar: FunctionComponent<PaymentsNavbarProps> = ({ history, match }) => {
  function back() {
    history.push(`/games/${match.params.gameId}/results`);
  }

  return (
    <div className="PaymentsNavbar">
      <div className="nav-control left" onClick={() => back()}>
        <FontAwesomeIcon className="angle-icon" icon={faAngleLeft} />
        <span className="back-label">Back</span>
      </div>
      <div className="page-title">
        Player Payments
      </div>
      <div className="nav-control right">
      </div>
    </div>
  );
}


export default withRouter(PaymentsNavbar);
