import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { withRouter, History, Match } from "react-router-dom";
import { store } from "store/store";
import { Player, Buyin } from "types/types";

import "./GamePage.scss";
import PlayerRow from "./PlayerRow/PlayerRow";
import NewPlayerRow from "./NewPlayerRow/NewPlayerRow";
import GameNavbar from "./GameNavbar/GameNavbar";
import Footer from "components/Footer/Footer";
import { SET_DRAFT_PLAYER } from "store/actions";

type GamePageProps = {
  history: History;
  match: Match;
};

const GamePage: FunctionComponent<GamePageProps> = ({ history, match }) => {
  const { state, dispatch } = useContext(store);

  useEffect(() => {
    dispatch({ type: SET_DRAFT_PLAYER, draftPlayer: null });
  }, []);

  function getPlayerCount() {
    return state?.game?.players?.length || 0;
  }

  function getTotalBuyin() {
    const buyins =
      (state?.game?.players
        .map((player) => player.buyins || [])
        .flat() as Buyin[]) || [];
    return buyins
      .reduce((a, b) => a + b.amount, 0)
      .toFixed(2)
      .replace(/[.,]00$/, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function renderNewPlayer(): ReactNode {
    if (state?.draftPlayer !== null) return <NewPlayerRow />;
  }

  function cashout() {
    history.push(`/games/${match.params.gameId}/cashout`);
  }

  const gameName = state?.game?.id
    .substring(state?.game?.id.length - 4)
    .toUpperCase();
  const rawDate = state?.game?.created_at;
  const gameDate = rawDate
    ? new Date(rawDate).toLocaleDateString("en-US")
    : null;

  return (
    <div className="GamePage">
      <GameNavbar />
      <div className="game-summary">
        <div className="game-title">
          {gameName || "Game not found"} - {gameDate}
        </div>
        <div className="game-stats">
          <div className="stats-group">
            <div className="group-amount">{getPlayerCount()}</div>
            <div className="group-label">players</div>
          </div>
          <div className="stats-group">
            <div className="group-amount">
              <sup>$</sup>
              {getTotalBuyin()}
            </div>
            <div className="group-label">on the table</div>
          </div>
        </div>
        <div className="game-cashout" onClick={() => cashout()}>
          Cashout game
        </div>
      </div>
      <div className="players-container">
        {renderNewPlayer()}
        {state?.game?.players.map((player) => renderPlayer(player))}
      </div>
      <Footer />
    </div>
  );
};

const renderPlayer = (player: Player): ReactNode => {
  return <PlayerRow key={player.id} player={player} />;
};

export default withRouter(GamePage);
