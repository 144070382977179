import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { store } from 'store/store';

import './PaymentsPage.scss';
import { Payment } from 'types/types';
import PaymentRow from './PaymentRow/PaymentRow';
import PaymentsNavbar from './PaymentsNavbar/PaymentsNavbar';
import Footer from 'components/Footer/Footer';

const PaymentsPage: FunctionComponent = () => {
  const { state } = useContext(store);

  return (
    <div className="PaymentsPage">
      <PaymentsNavbar />
      <div className="payments-container">
        { state?.game?.payments.map(payment => renderPayment(payment)) }
      </div>
      <Footer />
    </div>
  );
}

const renderPayment = (payment: Payment): ReactNode => {
  return (
    <PaymentRow key={payment.id} payment={payment} />
  );
}


export default withRouter(PaymentsPage);
