import React, { FunctionComponent } from 'react';
import { Player } from 'types/types';
import './CashoutRow.scss';

type CashoutRowProps = {
  player: Player,
  amount: number,
  updateAmount: Function,
}

const CashoutRow: FunctionComponent<CashoutRowProps> = ({ player, amount, updateAmount }) => {
  return (
    <div className="CashoutRow">
      <div className="player-name">
        { player.player_name } cashes out:
      </div>
      <div className="cashout-details">
        <input
          className="cashout-input"
          type="number"
          placeholder="Enter an amount"
          value={amount || ''}
          onChange={event => updateAmount(event.target.value)}
        />
      </div>
    </div>
  );
}

export default CashoutRow;
