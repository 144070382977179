import React, { FunctionComponent, useContext } from 'react';
import { withRouter, History, Match } from 'react-router-dom';
import { store } from 'store/store';
import { SET_PLAYER } from 'store/actions';
import { Player } from 'types/types';

import './ResultsRow.scss';

type ResultsRowProps = {
  player: Player,
  history: History,
  match: Match,
}

const ResultsRow: FunctionComponent<ResultsRowProps> = ({ player, history, match }) => {
  const state = useContext(store);
  const { dispatch } = state;

  function viewPlayer(player) {
    dispatch({ type: SET_PLAYER, player });
    history.push(`/games/${match.params.gameId}/players/${player.id}`);
  }

  function getSignedProfit() {
    return player.cashouts.reduce((a, b) => a + b.amount, 0) - player.buyins.reduce((a, b) => a + b.amount, 0);
  }

  function getTotalProfit() {
    return getSignedProfit().toFixed(2).replace(/[.,]00$/, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getTotalLoss() {
    return -getSignedProfit().toFixed(2).replace(/[.,]00$/, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function renderPlayerBalance() {
    if (getSignedProfit() >= 0) {
      return (
        <div className="player-balance green">
          <div className="balance-label">booked a profit of</div>
          <div className="balance-amount">${getTotalProfit()}</div>
        </div>
      );
    } else {
      return (
        <div className="player-balance red">
          <div className="balance-label">booked a loss of</div>
          <div className="balance-amount">${getTotalLoss()}</div>
        </div>
      );
    }
  }

  return (
    <div className="ResultsRow" onClick={() => viewPlayer(player)}>
      <div className="player-name">
        { player.player_name }
      </div>
      <div className="player-details">
        { renderPlayerBalance() }
      </div>
    </div>
  );
}

export default withRouter(ResultsRow);
