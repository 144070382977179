import React, { FunctionComponent, useContext } from "react";
import { Buyin } from "types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import * as buyinService from "services/buyin.service";
import { store } from "store/store";
import { SET_PLAYER } from "store/actions";
import { Player } from "types/types";

import "./BuyinRow.scss";

type BuyinRowProps = {
  buyin: Buyin;
};

const BuyinRow: FunctionComponent<BuyinRowProps> = ({ buyin }) => {
  const { dispatch, state } = useContext(store);

  const deleteBuyin = async () => {
    const buyins = (state?.player?.buyins || []).filter(
      (existingBuyin) => existingBuyin.id !== buyin.id
    );
    const newPlayer = { ...state.player, buyins } as Player;
    const newPlayers = state?.game?.players.map((player) => {
      if (player.id === newPlayer.id) {
        return newPlayer;
      } else {
        return player;
      }
    });
    const newGame = {
      ...state.game,
      players: newPlayers,
    };

    await buyinService.deleteBuyin({ id: buyin.id });
    dispatch({ type: SET_PLAYER, player: newPlayer, game: newGame });
  };

  return (
    <div className="BuyinRow">
      <div className="buyin-time">
        {new Date(buyin.created_at).toLocaleTimeString("en-US")}
      </div>
      <div className="buyin-details">
        <div className="buyin-amount">
          <div className="buyin-label">bought in for</div>
          <div className="buyin-amount">${buyin.amount}</div>
        </div>

        <FontAwesomeIcon
          className="times-icon"
          icon={faTimes}
          onClick={deleteBuyin}
        />
      </div>
    </div>
  );
};

export default BuyinRow;
