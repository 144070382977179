import React, { FunctionComponent } from 'react';
import './Footer.scss';

const Footer: FunctionComponent = () => {
  return (
    <div className="Footer">
      <div className="footer-message">
        Got feedback? Want a feature?
      </div>

      <div className="contact-button">Contact us</div>
    </div>
  );
}


export default Footer;
