import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { store } from "store/store";
import { Player } from "types/types";

import "./CashoutPage.scss";
import CashoutRow from "./CashoutRow/CashoutRow";
import CashoutNavbar from "./CashoutNavbar/CashoutNavbar";
import Footer from "components/Footer/Footer";
import { SET_CASHOUTS } from "store/actions";

const CashoutPage: FunctionComponent = () => {
  const { state, dispatch } = useContext(store);

  useEffect(() => {
    dispatch({ type: SET_CASHOUTS, cashouts: {} });
  }, []);

  function getPlayerCount() {
    return state.game?.players?.length || 0;
  }

  function getRemainingBuyin() {
    const cashoutTotal = Object.values(state.cashouts).reduce(
      (a, b) => a + parseFloat(b),
      0
    );
    const buyins =
      state.game?.players.map((player) => player.buyins).flat() || [];
    const buyinTotal = buyins.reduce((a, b) => a + b.amount, 0);

    return (buyinTotal - cashoutTotal)
      .toFixed(2)
      .replace(/[.,]00$/, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function updateAmount(player: Player, amount: number) {
    dispatch({
      type: SET_CASHOUTS,
      cashouts: {
        ...state.cashouts,
        [player.id]: amount || 0,
      },
    });
  }

  function renderCashout(player: Player): ReactNode {
    return (
      <CashoutRow
        key={player.id}
        player={player}
        amount={state.cashouts[player.id]}
        updateAmount={(amount) => updateAmount(player, amount)}
      />
    );
  }

  function cashoutClass() {
    const remaining = parseFloat(getRemainingBuyin());
    if (remaining > 0) {
      return "blue";
    } else if (remaining < 0) {
      return "red";
    } else {
      return "green";
    }
  }

  const gameName = state?.game?.id
    .substring(state?.game?.id.length - 4)
    .toUpperCase();
  const rawDate = state?.game?.created_at;
  const gameDate = rawDate
    ? new Date(rawDate).toLocaleDateString("en-US")
    : null;

  return (
    <div className="CashoutPage">
      <CashoutNavbar />
      <div className="game-summary">
        <div className="game-title">
          {gameName || "Game not found"} - {gameDate}
        </div>
        <div className="game-stats">
          <div className="stats-group">
            <div className="group-amount">{getPlayerCount()}</div>
            <div className="group-label">players</div>
          </div>
          <div className="stats-group">
            <div className={`group-amount ${cashoutClass()}`}>
              <sup>$</sup>
              {getRemainingBuyin()}
            </div>
            <div className="group-label">remaining</div>
          </div>
        </div>
      </div>
      <div className="cashouts-container">
        {state?.game?.players.map((player) => renderCashout(player))}
      </div>
      <Footer />
    </div>
  );
};

export default CashoutPage;
