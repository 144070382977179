import React, { FunctionComponent } from 'react'
import { withRouter, History, Match } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import './ResultsNavbar.scss';

type ResultsNavbarProps = {
  history: History,
  match: Match,
}

const ResultsNavbar: FunctionComponent<ResultsNavbarProps> = ({ history, match }) => {
  function back() {
    history.push('/');
  }

  function viewPayments() {
    history.push(`/games/${match.params.gameId}/payments`);
  }

  return (
    <div className="ResultsNavbar">
      <div className="nav-control left" onClick={() => back()}>
        <FontAwesomeIcon className="angle-icon" icon={faAngleLeft} />
        <span className="back-label">Back</span>
      </div>
      <div className="page-title">
        Game Results
      </div>
      <div className="nav-control right" onClick={() => viewPayments()}>
        <span className="payments-label">Payments</span>
      </div>
    </div>
  );
}


export default withRouter(ResultsNavbar);
