import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react';

import './HomePage.scss';
import { Game } from 'types/types';
import { retrieveGames } from 'services/storage.service';
import { getGames } from 'services/game.service';
import HomeNavbar from './HomeNavbar/HomeNavbar';
import GameRow from './GameRow/GameRow';
import Footer from 'components/Footer/Footer';

const initialGames: Game[] = [];

const HomePage: FunctionComponent = () => {
  const [games, setGames] = useState(initialGames);

  useEffect(() => {
    async function refreshGames() {
      const gameIds = retrieveGames().map(game => game.id);
      const games = await getGames(gameIds);
      setGames(games);
    }

    refreshGames();
  }, []);

  const buyins = games.map(game => game.players).flat().map(player => player.buyins).flat() || [];
  const totalBuyin = buyins.reduce((a, b) => a + b.amount, 0).toFixed(2).replace(/[.,]00$/, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <div className="HomePage">
      <HomeNavbar />
      <div className="home-summary">
        <div className="home-title">Welcome to PokerHost</div>
        <div className="home-stats">
          <div className="stats-group">
            <div className="group-amount">{games.length}</div>
            <div className="group-label">games</div>
          </div>
          <div className="stats-group">
            <div className="group-amount"><sup>$</sup>{totalBuyin}</div>
            <div className="group-label">all-time buyin</div>
          </div>
        </div>
      </div>
      <div className="games-container">
        { games.map(game => renderGame(game)) }
      </div>
      <Footer />
    </div>
  );
}

const renderGame = (game: Game): ReactNode => {
  return (
    <GameRow key={game.id} game={game} />
  );
}


export default HomePage;
